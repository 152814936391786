import { ChromePicker } from 'react-color';
import { MdColorLens } from 'react-icons/md';
import styled, { css } from 'styled-components';

import { SelectData } from '@gbs-monorepo-packages/common';
import { colors, radii, shadows } from '@gbs-monorepo-packages/styles';

export const ColorPickerContainer = styled.div`
  align-items: center;
  display: flex;
  max-width: 100%;
  padding: 0;
`;

export const ColorPickerIcon = styled(MdColorLens)`
  color: ${colors.button};
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  margin-right: 1rem;
`;

export const ColorPreview = styled.div`
  text-align: center;
  display: block;
  height: 3.25rem;
  width: 3.25rem;
  padding: 0;
  margin: 0;
  margin-top: 2px;
  border: 1px solid ${colors.fieldFocus};
  border-top-left-radius: ${radii.px};
  border-bottom-left-radius: ${radii.px};
`;

export const CustomChromePicker = styled(ChromePicker)`
  width: 60% !important;
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 100%;
  transform: translateX(10px);
`;

export const ErrorMessageContainer = styled.div`
  font-size: 0.875rem;
  color: ${colors.red300};
  margin: -5px 0px 15px 0px;
`;

export const ErrorMessage = styled.p`
  margin-top: 5px;
`;

export const Label = styled.span`
  background-color: ${colors.white300};
  color: ${colors.labelText};
  font-size: 0.875rem;
  left: 16px;
  max-width: 90%;
  overflow: hidden;
  padding: 1px 6px;
  position: absolute;
  text-overflow: ellipsis;
  top: 50%;
  transform-origin: 0;
  transform: translateY(-50%);
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out,
    padding 0.15s ease-in-out;
  user-select: none;
  white-space: nowrap;
  z-index: 1;
`;

export const SelectDataCustom = styled(SelectData)`
  border: none;
  border-radius: ${radii.px};
`;

interface ISquareProps {
  color: string;
}

export const Square = styled.div<ISquareProps>`
  box-shadow: ${shadows.modal};
  width: 17px;
  height: 17px;
  overflow: hidden;
  z-index: 1;
  margin-left: 5px;
  margin-right: 5px;
  border: 1px solid ${colors.inputBorderLight};
  background-color: ${({ color }) => color};
  display: inline-block;
  vertical-align: middle;
`;

export const Input = styled.input`
  background: none;
  border: none;
  color: ${colors.text};
  flex-grow: 1;
  font-size: 1rem;
  height: 3.25rem;
  line-height: 1.1;
  outline: none;
  padding: 0 1rem;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
`;

interface IFieldsetProps {
  readonly filled: boolean;
  readonly isInvalid?: boolean;
}

export const Fieldset = styled.fieldset<IFieldsetProps>`
  flex: 1;
  all: unset;
  align-items: center;
  background-color: ${colors.white300};
  border-radius: ${radii.px};
  border: 1px solid ${colors.inputBorder} !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2px;
  position: relative;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
  flex-grow: 1;

  &:focus-within {
    border: 1px solid ${colors.fieldFocus} !important;

    & ${Label} {
      color: ${colors.fieldFocus};
      top: -2px;
      transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
      white-space: normal;
    }
  }

  & button[type='button'] {
    border: none;
  }

  ${({ filled = false, isInvalid = false }) =>
    filled &&
    (!isInvalid
      ? css`
          border: 1px solid var(--gbs-color-blue);

          & ${Label} {
            color: var(--gbs-color-blue);
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `
      : css`
          border: 1px solid ${colors.red300};

          & ${Label} {
            color: ${colors.red300};
            top: -2px;
            transform: scale(0.88) translateX(-0.5rem) translateY(-50%);
            white-space: normal;
          }
        `)}

  & ${Input} ~ * ${SelectDataCustom} {
    border-left: 1px solid ${colors.divider};
  }
`;
