import { type HTMLAttributes, forwardRef } from 'react';

import { Content } from './styles';

export interface IFormContentProps extends HTMLAttributes<HTMLDivElement> {
  dataCy?: string;
}

export const FormContent = forwardRef<HTMLDivElement, IFormContentProps>(
  // eslint-disable-next-line no-restricted-syntax
  function FormContent({ dataCy = 'content', ...props }, ref): JSX.Element {
    return <Content {...props} data-cy={dataCy} ref={ref} />;
  }
);
