import { forwardRef } from 'react';

import {
  BaseSelect,
  BaseSelectArrowDown,
  BaseSelectArrowUp,
  BaseSelectIndicator,
  BaseSelectItem,
  BaseSelectItemText,
  BaseSelectViewport,
  type IBaseSelectProps,
} from '../BaseSelect';
import { Container } from './styles';

export interface IHTMLDivElementExtension extends HTMLDivElement {
  dataCy?: string;
  name?: string;
  placeholder?: string;
}

export interface IBaseSelectPropsExtension
  extends Omit<IBaseSelectProps, 'children'> {
  dataCy?: string;
  name?: string;
  placeholder?: string;
}

export interface IT {
  key: number | string;
  value: JSX.Element | string;
}

export interface ISelectDataProps<
  T extends { key: number | string; value: JSX.Element | string }
> extends IBaseSelectPropsExtension {
  data: T[];
  label?: string;
}

export const SelectData = forwardRef<
  IHTMLDivElementExtension,
  ISelectDataProps<IT>
>(
  // eslint-disable-next-line no-restricted-syntax
  function SelectData(
    {
      data,
      dataCy = 'select-item',
      name = 'select-name',
      placeholder = '',
      ...props
    },
    ref
  ): JSX.Element {
    return (
      <Container data-cy="select-container" ref={ref}>
        <BaseSelect
          dataCy={dataCy}
          placeholder={placeholder}
          name={name}
          {...props}
        >
          <BaseSelectArrowUp data-cy="arrow-up" />

          <BaseSelectViewport data-cy="view-port">
            {data.map(({ key, value }) => (
              <BaseSelectItem
                key={key}
                value={`${key}`}
                data-cy={`option-${String(value).toLowerCase()}`}
              >
                <BaseSelectItemText data-cy={`${String(value).toLowerCase()}`}>
                  {value}
                </BaseSelectItemText>
                <BaseSelectIndicator data-cy="item-selected-indicator" />
              </BaseSelectItem>
            ))}
          </BaseSelectViewport>

          <BaseSelectArrowDown data-cy="arrow-down" />
        </BaseSelect>
      </Container>
    );
  }
);
