import { forwardRef } from 'react';

import { Label, type LabelProps } from '@radix-ui/react-label';

export interface IBaseLabelProps extends LabelProps {}

export const BaseLabel = forwardRef<
  HTMLLabelElement,
  IBaseLabelProps
  // eslint-disable-next-line no-restricted-syntax
>(function BaseLabel({ ...props }, ref): JSX.Element {
  return <Label {...props} ref={ref} />;
});
