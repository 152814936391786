import { z } from 'zod';

import { color, colorRequired } from './utils/color';

export const globalStyleTemplateColorsUpdate = z.object({
  primaryColor: color,
  secondaryColor: color,
  backgroundColor: color,
  accentColor: color,
});

export const globalStyleSchema = z.object({
  buttonColor: colorRequired.nullable(),
  buttonFontColor: colorRequired.nullable(),
  headlineFont: z.string().trim(),
  headlineFontColor: colorRequired.nullable(),
  headlineFontSize: z.number().nullable(),
  headlineFontSizeUnit: z.string().nullable(),
  hueRotate: z.number().nullable(),
  pageWidth: z.number().nullable(),
  pageWidthUnit: z.string().nullable(),
  paragraphFont: z.string().trim(),
  paragraphFontColor: colorRequired.nullable(),
  paragraphFontSize: z.number().nullable(),
  paragraphFontSizeUnit: z.string().nullable(),
  primaryColor: colorRequired.nullable(),
  secondaryColor: colorRequired.nullable(),
  templateColors: globalStyleTemplateColorsUpdate,
  additionalColors: z.array(z.object({ color })),
});

export type GlobalStyleSchema = z.infer<typeof globalStyleSchema>;

export type ITemplateColorsUpdateSchema = z.infer<
  typeof globalStyleTemplateColorsUpdate
>;
