import { type CSSProperties } from 'react';
import styled, { css } from 'styled-components';

import {
  BaseLoading,
  BaseSlider,
  FormBase,
  FormModal,
  SelectData,
} from '@gbs-monorepo-packages/common';
import { colors, radii, scrollbar } from '@gbs-monorepo-packages/styles';

export const FormModalCustom = styled(FormModal)`
  overflow: unset;
  max-height: unset;
`;

export const Container = styled(FormBase.Content)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 75vh;
  padding-top: 0.25rem;
  margin-bottom: 0.25rem;

  ${scrollbar};
`;

export const SmallInputTextCustom = styled(FormBase.InputText)`
  width: unset;
`;

export const SelectDataCustom = styled(SelectData)`
  border: none;
  border-radius: ${radii.px};
`;

const selectDataContentCustom: CSSProperties = {
  left: '8.6%',
  position: 'absolute',
  width: '91.4%',
};

export const SmallSelectDataCustom = styled(SelectDataCustom).attrs(
  ({ customContentStyle = selectDataContentCustom }) => ({
    customContentStyle,
  })
)``;

export const InputContentCustom = styled(FormBase.InputContent)`
  & ${SmallInputTextCustom} ~ * ${SelectDataCustom} {
    border-left: 1px solid ${colors.divider};
  }
`;

export const SliderCustom = styled(BaseSlider)`
  flex-grow: 5;
  padding-right: 1rem;
`;

export const ColorPreview = styled.div`
  text-align: center;
  display: block;
  height: 3.25rem;
  width: 3.25rem;
  padding: 0;
  margin: 0;
  border: 1px solid ${colors.fieldFocus};
  border-top-left-radius: ${radii.px};
  border-bottom-left-radius: ${radii.px};
`;

export const Loading = styled(BaseLoading)`
  height: 3.25rem;
  width: 3.25rem;
  margin-top: 1.5rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 2rem;
`;

interface ITemplateColorSectionProps {
  readonly hasColors?: boolean | string;
}

export const TemplateImagesColorsSection = styled.div<ITemplateColorSectionProps>`
  ${({ hasColors = true }) =>
    hasColors === false
      ? css`
          position: relative;
        `
      : ''};
`;

export const TemplateImagesColorsTitle = styled.p`
  padding: 0.75rem 0 1.5rem;
  font-weight: bold;
`;

export const OverlayDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OverlayText = styled.p`
  font-size: 18px;
  text-align: center;
`;

export const ColorPickerContainer = styled.div`
  margin-top: 1rem;
`;
