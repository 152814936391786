import { CheckboxContent, type ICheckboxContentProps } from './CheckboxContent';
import { FormContent, type IFormContentProps } from './FormContent';
import { FormGrid, type IFormGridProps } from './FormGrid';
import {
  FormInputDate,
  FormInputText,
  type IFormInputDateProps,
  type IFormInputTextProps,
} from './FormInput';
import {
  FormInputActionBase,
  type IFormInputActionPropsBase,
} from './FormInputActions';
import {
  FormInputContent,
  type IFormInputContentProps,
} from './FormInputContent';
import { type IFormProvider, Provider } from './FormProvider';
import { FormRoot, type IFormRootsProps } from './FormRoot';

export type { IFormInputTextProps } from './FormInput';

export const FormBase = {
  Root: FormRoot as React.FC<IFormRootsProps>,
  Content: FormContent as React.FC<IFormContentProps>,
  Grid: FormGrid as React.FC<IFormGridProps>,
  CheckboxContent: CheckboxContent as React.FC<ICheckboxContentProps>,
  InputActions: FormInputActionBase as React.FC<IFormInputActionPropsBase>,
  InputContent: FormInputContent as React.FC<IFormInputContentProps>,
  InputText: FormInputText as React.FC<IFormInputTextProps>,
  InputDate: FormInputDate as React.FC<IFormInputDateProps>,
  Provider: Provider as React.FC<IFormProvider>,
};

export type * from './FormRoot';
export type * from './FormContent';
export type * from './FormGrid';
export type * from './CheckboxContent';
export type * from './FormInputActions';
export type * from './FormInputContent';
export type * from './FormInput';
export type * from './FormProvider';
