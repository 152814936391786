import {
  type CSSProperties,
  type ChangeEvent,
  type HTMLAttributes,
  type KeyboardEvent as ReactKeyboardEvent,
  type ReactNode,
  createContext,
  forwardRef,
  useCallback,
  useRef,
} from 'react';
import { type ListItemKeySelector, type ListOnScrollProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import * as Select from '@radix-ui/react-select';

import { BaseSelect, type IBaseSelectProps } from '../BaseSelect';
import {
  Container,
  Fieldset,
  IndicatorContainer,
  Input,
  Item,
  ListContainer,
  Loading,
  LoadingContainer,
  SelectDataItemContainer,
  SelectIcon,
  Viewport,
} from './styles';

export interface ISelectFilterData extends Record<string, any> {
  id: number;
}

const getItemKey: ListItemKeySelector<
  ISelectFilterListData<ISelectFilterData>
> = (index, { data }) => data.at(index)?.id ?? `not-found-${index}`;

export type ISelectFilterValue<T extends ISelectFilterData> = T & {
  index?: number;
};

export interface ISelectFilterListData<T extends ISelectFilterData> {
  attribute: keyof T;
  data: T[];
  itemChild?: (props: ISelectFilterChildrenItemProps<T>) => JSX.Element;
  value?: ISelectFilterValue<T> | null;
}

export interface ISelectFilterChildrenItemProps<T extends ISelectFilterData>
  extends ISelectFilterItemProps {
  data: T;
}

export interface ISelectFilterProps<T extends ISelectFilterData>
  extends Omit<
    IBaseSelectProps,
    'children' | 'defaultValue' | 'onValueChange' | 'value'
  > {
  name?: string;
  attribute: keyof T;
  children?: (props: ISelectFilterChildrenItemProps<T>) => JSX.Element;
  data: T[];
  dataCy?: string;
  hasMore: boolean;
  itemHeight?: number;
  loading?: boolean;
  next: () => Promise<void> | void;
  onSearch?: (search: string) => Promise<void> | void;
  onValueChange?: (value: ISelectFilterValue<T>) => void;
  onOpenChange?: (open: boolean) => void;
  pageSize: number;
  searchPlaceholder?: string;
  value: ISelectFilterValue<T> | null;
}

const delaySearch = 1000;
const defaultHeight = 30;
const FIX_HEIGHT = 55;

export const SelectFilter = <T extends ISelectFilterData>({
  attribute,
  children,
  data,
  name = 'select-filter',
  dataCy = 'select-filter',
  hasMore,
  itemHeight = defaultHeight,
  next,
  onOpenChange,
  onSearch,
  onValueChange,
  pageSize,
  searchPlaceholder = 'Search',
  value,
  ...props
}: ISelectFilterProps<T>): JSX.Element => {
  const lastSearch = useRef('');
  const searchTimeOutHandler = useRef<NodeJS.Timeout>();

  const inputRef = useRef<HTMLInputElement>(null);
  const scrollElementRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef(NaN);
  const loadingNext = useRef(false);

  const handleSearch = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const newSearch = target.value.trimStart();
      if (newSearch.length <= 50) {
        clearTimeout(searchTimeOutHandler.current);
        if (!newSearch || lastSearch.current !== newSearch) {
          searchTimeOutHandler.current = setTimeout(() => {
            lastSearch.current = newSearch;
            void onSearch?.(newSearch.trimEnd());
          }, delaySearch);
        }
      }
    },
    [onSearch, lastSearch]
  );

  const handleOnKeyPressFocus = ({
    key,
  }: ReactKeyboardEvent<HTMLDivElement>) => {
    if (key === 'Backspace' || /^([A-Za-z0-9_ ])$/.test(key)) {
      inputRef.current?.focus();
    }
  };

  const handleSelectOpenChange = useCallback(
    (open: boolean) => {
      if (open) {
        inputRef.current?.focus();
      } else {
        const clearSearch = '';

        clearTimeout(searchTimeOutHandler.current);
        if (lastSearch.current !== clearSearch) {
          lastSearch.current = clearSearch;
          void onSearch?.(clearSearch.trimEnd());
        }
      }
      onOpenChange?.(open);
    },
    [onOpenChange, onSearch]
  );

  const handleScroll = useCallback(
    ({ scrollOffset, scrollDirection }: ListOnScrollProps) => {
      const scrollElement = scrollElementRef.current;
      const scrollTop = lastScrollTop.current;

      if (
        scrollOffset < itemHeight &&
        scrollDirection === 'backward' &&
        scrollElement &&
        !isNaN(scrollTop)
      ) {
        scrollElement.scrollTo(0, scrollTop);
        lastScrollTop.current = NaN;
      }
    },
    [itemHeight]
  );

  const loadMoreItems = useCallback(
    async (_startIndex: number, _stopIndex: number) => {
      if (loadingNext.current) {
        return;
      }
      loadingNext.current = true;
      await next();
      lastScrollTop.current =
        value !== null ? scrollElementRef.current?.scrollTop ?? NaN : NaN;
      loadingNext.current = false;
    },
    [next, value]
  );

  const handleValueChange = useCallback(
    (value: string) => {
      const id = parseInt(value);
      const index = data.findIndex((item) => item.id === id);
      const newValue: ISelectFilterValue<T> | null =
        index === -1
          ? null
          : {
              ...data[index],
              index,
            };
      newValue && onValueChange?.(newValue);
    },
    [data, onValueChange]
  );

  const dataLength = hasMore ? data.length + 1 : data.length;
  const listMaxHeight = itemHeight * pageSize;
  const listHeight = Math.min(listMaxHeight, itemHeight * data.length);
  const isItemLoaded = (index: number) => !hasMore || index < data.length;

  // FIXME(PFM-640): This is a workaround to avoid a type error
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const itemData = {
    attribute,
    data,
    itemChild: children,
    value,
  } as ISelectFilterListData<ISelectFilterData>;

  return (
    <Container data-cy={`${dataCy}-container`}>
      <BaseSelect
        {...props}
        name={name}
        dataCy={`${dataCy}`}
        onKeyPress={handleOnKeyPressFocus}
        onOpenChange={handleSelectOpenChange}
        onValueChange={handleValueChange}
        overwritePortalHeight={listHeight + FIX_HEIGHT}
        value={value?.id.toString() ?? ''}
      >
        <Fieldset>
          <Input
            id={`${name}-search`}
            data-cy={`input-${dataCy}`}
            onChange={handleSearch}
            placeholder={searchPlaceholder}
            ref={inputRef}
            type="text"
          />
        </Fieldset>

        <Viewport data-cy="itens-container">
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={dataLength}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <InnerSelectContext.Provider value={itemData}>
                <ListContainer
                  height={listHeight}
                  innerElementType={(props: IInnerSelectProps) => (
                    <InnerSelectElement
                      {...props}
                      dataCy={dataCy}
                      itemHeight={itemHeight}
                    />
                  )}
                  itemCount={dataLength}
                  itemData={itemData}
                  itemKey={getItemKey}
                  itemSize={itemHeight}
                  onItemsRendered={onItemsRendered}
                  onScroll={handleScroll}
                  outerRef={scrollElementRef}
                  ref={ref}
                  width={'100%'}
                >
                  {({
                    data: { attribute, data, itemChild, value },
                    index,
                    style,
                  }) => {
                    if (index === value?.index) {
                      return null;
                    }
                    return (
                      <SelectFilterListItem
                        attribute={attribute}
                        data={data.at(index)}
                        dataCy={dataCy}
                        itemHeight={itemHeight}
                        itemOffset={
                          index < (value?.index ?? NaN) ? itemHeight : 0
                        }
                        itemStyle={style}
                      >
                        {itemChild}
                      </SelectFilterListItem>
                    );
                  }}
                </ListContainer>
              </InnerSelectContext.Provider>
            )}
          </InfiniteLoader>
        </Viewport>
      </BaseSelect>
    </Container>
  );
};

export interface ISelectFilterItemProps
  extends HTMLAttributes<HTMLDivElement>,
    Select.SelectItemProps {
  dataCy: string;
  text: string;
}

export const SelectFilterItem = forwardRef<
  HTMLDivElement,
  ISelectFilterItemProps
>(
  // eslint-disable-next-line no-restricted-syntax
  function SelectFilterItem(
    { dataCy = 'select-filter-item', text, value, ...props },
    ref
  ): JSX.Element {
    return (
      <Item {...props} data-cy={dataCy} value={value} ref={ref}>
        <Select.ItemText data-cy="item-text">{text}</Select.ItemText>
        <IndicatorContainer data-cy="item-selected-indicator">
          <SelectIcon />
        </IndicatorContainer>
      </Item>
    );
  }
);

export interface ISelectFilterListItemProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children'>,
    Omit<ISelectFilterItemProps, 'children' | 'text' | 'value'> {
  attribute: keyof ISelectFilterData;
  children?: (
    props: ISelectFilterChildrenItemProps<ISelectFilterData>
  ) => JSX.Element;
  data?: ISelectFilterData;
  dataCy: string;
  itemHeight: number;
  itemOffset?: number;
  itemStyle?: CSSProperties;
}

export const SelectFilterListItem = forwardRef<
  HTMLDivElement,
  ISelectFilterListItemProps
>(
  // eslint-disable-next-line no-restricted-syntax
  function SelectFilterListItem(
    {
      attribute,
      children,
      data = null,
      dataCy,
      itemHeight,
      itemOffset = 0,
      itemStyle: { top = 0, ...style } = {},
      ...props
    },
    ref
  ): JSX.Element {
    if (!data) {
      return (
        <LoadingContainer
          {...props}
          $itemOffset={itemOffset}
          $top={top}
          data-cy={`${dataCy}-loading`}
          ref={ref}
          style={style}
        >
          <Loading $height={itemHeight} $width={itemHeight} />
        </LoadingContainer>
      );
    }
    const value = data.id.toString();

    const text = attribute in data ? String(data[attribute]) : '-';

    return (
      <SelectDataItemContainer
        {...props}
        $itemOffset={itemOffset}
        $top={top}
        data-cy={`${dataCy}-item-${value}`}
        ref={ref}
        style={style}
      >
        {children?.({
          value,
          dataCy: text,
          text,
          data,
        }) ?? <SelectFilterItem value={value} dataCy={text} text={text} />}
      </SelectDataItemContainer>
    );
  }
);

interface IInnerSelectContextData
  extends ISelectFilterListData<ISelectFilterData> {}

const InnerSelectContext = createContext<IInnerSelectContextData>(
  {} as IInnerSelectContextData
);

interface IInnerSelectProps {
  children: ReactNode;
  dataCy?: string;
  itemHeight?: number;
  style?: CSSProperties;
}

const InnerSelectElement = forwardRef<HTMLDivElement, IInnerSelectProps>(
  // eslint-disable-next-line no-restricted-syntax
  function InnerSelect(
    {
      children,
      dataCy = 'select-filter',
      itemHeight = defaultHeight,
      ...props
    },
    ref
  ): JSX.Element {
    return (
      <InnerSelectContext.Consumer>
        {({ attribute, data, itemChild, value }) => (
          <div data-cy={`${dataCy}-inner`} ref={ref} {...props}>
            {value?.index !== undefined && (
              <SelectFilterListItem
                attribute={attribute}
                data={data.at(value.index)}
                dataCy={`${dataCy}-item-extra`}
                itemHeight={itemHeight}
              >
                {itemChild}
              </SelectFilterListItem>
            )}
            {children}
          </div>
        )}
      </InnerSelectContext.Consumer>
    );
  }
);
